import React from "react";
import config from "../manifest.json";

export default function Infobar({ user }) {
    if (!user) {
        return <nav className="navbar infobar fixed-bottom bg-body-tertiary">
            <div className="container-fluid">
                <div className="infobar-icons">
                </div>
                <div className="infotext">&copy; {config.copyright}</div>
            </div>
        </nav>;
    } else {
        return <nav className="navbar infobar fixed-bottom bg-body-tertiary">
            <div className="container-fluid">
                <div className="infobar-icons">
                    <a href="/" ><i id="infobarIconErfassung" title="Erfassung" className="bi bi-clock infobar-icon"></i></a>
                    <a href="/projekte" ><i id="infobarIconInfo" title="Projekte" className="bi bi-journal infobar-icon pointer"></i></a>
                    <i id="infobarIconOnline" className="bi bi-wifi infobar-icon" title="Online"></i>
                    {/* <i id="infobarIconRequest" className="bi-cloud infobar-icon" title="Request"></i> */}
                    {/* <a href="/login" ><i id="infobarIconAbmelden" title="Abmelden" className="bi bi-box-arrow-right infobar-icon pointer"></i></a> */}
                </div>
                <div className="infotext"> V{config.version} &copy; {config.copyright}</div>
            </div>
        </nav>;
    }
}
