  import React, { useEffect } from "react";
import Requests from '../components/functions/requests';
import Storage from '../components/functions/storage';
import Helper from '../components/functions/helper';
import { Modal } from 'bootstrap'

export default function Projekte({ user }) {

  let workData = Storage.getStorageValue("dtm-WorkData");
  useEffect(() => {
    
    if (workData.length > 0) {
      const date = new Date();
      const now = Helper.getDate(date);
      const parsedWorkData = JSON.parse(workData);
      const azstart = parsedWorkData.az.hasOwnProperty("azstart") ? parsedWorkData.az["azstart"] : "";
      let out = "";
      if (azstart.length > 0 && azstart.split(' ')[0] === now) {
        const projekte = parsedWorkData.projekte;
        let length = projekte.length - 1;

        let projektList = Storage.getStorageValue("dtm-Projekte");
        if (projektList) {
          projektList = JSON.parse(projektList);
        } else {
          projektList = [];
          projektList[0] = { id_projekt: '0', name: '' };
        }
        for (let i = 0; i < projekte.length; i++) {
          if (i === length && projekte[i]["projende"].length < 1) {
            let start = (projekte[i]["projstart"] + "").slice(-5);
            let projekt = (projekte[i]["projname"] + "");
            let taetigkeit = (projekte[i]["projtaet"] + "");
            let options = "";
            projektList.map((item) => (
              item.name === projekt ? options += `<option selected='selected' value=${item.id_projekt}>${item.name}</option>` : options += `<option value=${item.id_projekt}>${item.name}</option>`
            ));
            out += getOutTempl(start, "", projekt, taetigkeit, options, i);
          } else {
            let start = (projekte[i]["projstart"] + "").slice(-5);
            let ende = (projekte[i]["projende"] + "").slice(-5);
            let projekt = (projekte[i]["projname"] + "");
            let taetigkeit = (projekte[i]["projtaet"] + "");
            let options = "";
            projektList.map((item) => (
              item.name === projekt ? options += `<option selected='selected' value=${item.id_projekt}>${item.name}</option>` : options += `<option value=${item.id_projekt}>${item.name}</option>`
            ));
            out += getOutTempl(start, ende, projekt, taetigkeit, options, i);
          }
        }
        document.getElementById("proje-allout").innerHTML = out;
      }

      const modalDelDialog = new Modal(document.getElementById("delProjeDialog"), {});
      const projediv = document.getElementById("proje-allout");
      const trash = projediv.getElementsByClassName("proje-trash");
      for (let i = 0; i < trash.length; i++) {
        trash[i].addEventListener("click", (e) => {
          const index = parseInt(e.target.dataset.value);
          modalDelDialog.show();
          document.getElementById("execDelDialog").addEventListener("click", () => { execDelDialog(index, modalDelDialog); });
        });
      }

      const modalEditDialog = new Modal(document.getElementById("editTaetProjeDialog"), {});
      const edit = projediv.getElementsByClassName("proje-edit");
      for (let i = 0; i < edit.length; i++) {
        edit[i].addEventListener("click", (e) => {
          const index = parseInt(e.target.dataset.value);
          let taetigkeiten = document.getElementsByClassName("proje-taetigkeit");
          for (let i = 0; i < taetigkeiten.length; i++) {
            if (i === index) {
              let field = document.getElementById("proje-taet-text-big");
              field.value = taetigkeiten[i].value;
              break;
            }
          }
          modalEditDialog.show();
          document.getElementById("execEditDialog").addEventListener("click", () => { execEditDialog(index, modalEditDialog); });
        });
      }

      let element = document.getElementsByTagName("input");
      for (let i = 0; i < element.length; i++) {
        element[i].addEventListener("change", () => { saveOnChange(); });
      }
      element = document.getElementsByTagName("input");
      for (let i = 0; i < element.length; i++) {
        element[i].addEventListener("input", () => { saveOnChange(); });
      }
      element = document.getElementsByTagName("select");
      for (let i = 0; i < element.length; i++) {
        element[i].addEventListener("change", () => { saveOnChange(); });
      }
      element = document.getElementsByTagName("textarea");
      for (let i = 0; i < element.length; i++) {
        element[i].addEventListener("change", () => { saveOnChange(); });
      }
    
      const saveOnChange = () => {
        const elem = document.getElementById("headline");
        if (document.getElementById("unsaveWarning") === null) {
          document.getElementById("proje-save").className += " danger";
          elem.insertAdjacentHTML("afterend", "<div id='unsaveWarning' class='error'>Es sind ungespeicherte Daten vorhanden!</div>");          
        }
      };
    }
  });

  const execDelDialog = (index, dialog) => {
    handleClick("proje-trash", Helper, Storage, user, index);
    dialog.hide();
  }

  const execEditDialog = (index, dialog) => {
    const text = document.getElementById("proje-taet-text-big").value;
    let taetigkeiten = document.getElementsByClassName("proje-taetigkeit");
    for (let i = 0; i < taetigkeiten.length; i++) {
      if (i === index) { 
        taetigkeiten[i].value = text;
        break;
      }
    }
    dialog.hide();
  }

  const handleClick = async (id, helper, storage, user, index) => {
    const date = new Date();
    const day = helper.getDate(date);
    switch (id) {
      case "proje-save": {
        if (workData.length > 0) {
          // workData = {
          //   az: {
          //   },
          //   projekte: [
          //     {
          //       projstart: ,
          //       projende: ,
          //       projname: ,
          //       projtaet: 
          //     }
          //   ],
          //   pausen: [],
          // };
          let parsedWorkData = JSON.parse(workData);
          let projekte = parsedWorkData.projekte;
          const arrVon = document.getElementsByName("proje-von[]");
          const arrBis = document.getElementsByName("proje-bis[]");
          const arrProjekt = document.getElementsByName("proje-projekt[]");
          const arrTaetigkeit = document.getElementsByName("proje-taetigkeit[]");
          for (let i = 0; i < projekte.length; i++) {
            const von = day + " " + arrVon[i].value;
            if (projekte[i].projstart !== von) {
              projekte[i].projstart = von;
            }
            const value = arrBis[i].value;
            const bis = value.length > 0 ? day + " " + value : value;
            if (projekte[i].projende !== bis) {
              projekte[i].projende = bis;
            }
            const pname = arrProjekt[i].selectedOptions[0].text;
            if (projekte[i].projname !== pname) {
              projekte[i].projname = pname;
            }
            let taetigkeit = arrTaetigkeit[i].value;
            if (projekte[i].projtaet !== taetigkeit) {
              projekte[i].projtaet = taetigkeit.replace("'", "");
            }
          }
          workData = JSON.stringify(parsedWorkData);
          storage.setStorageValue("dtm-WorkData", workData)
          const reqData = await Requests.asyncDTMPostRequest(id, workData, user);
          if (reqData.projesave) {
            window.location.assign("/");
          } else {
            document.getElementById("error-info").innerHTML = "<span class='error'>Es ist ein Fehler aufgetreten.</span>";
          }
        }
        break;
      }
      case "proje-trash": {
        if (workData.length > 0) {
          let parsedWorkData = JSON.parse(workData);
          let projekte = parsedWorkData.projekte;
          projekte.splice(index, 1);
          workData = JSON.stringify(parsedWorkData);
          storage.setStorageValue("dtm-WorkData", workData)
          const reqData = await Requests.asyncDTMPostRequest(id, workData, user);
          if (reqData.projetrash) {
            window.location.assign("/");
          } else {
            document.getElementById("error-info").innerHTML = "<span class='error'>Es ist ein Fehler aufgetreten.</span>";
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  const getOutTempl = (start, ende, projekt, taetigkeit, options, i) => {
    return `<div class="padBottom20">
              <br />
              <div id="error-info"></div>
              <div class='proje-allout-entry'>Zeit:</div>
              <input name="proje-von[]" type="text" class="form-control proje-von" placeholder="von" value="${start}" />
              <input name="proje-bis[]" type="text" class="form-control proje-bis" placeholder="bis" value="${ende}"  />
              <i title="L&ouml;schen" data-value="${i}" class="bi bi-trash infobar-icon pointer padLeft10 proje-trash"></i>
              <br /><div class='proje-allout-entry'>Projekt:</div>
              <select class="form-control proje-projekt" name="proje-projekt[]" placeholder="Projektauswahl" defaultValue="disabledEntry">
                <option key="0" disabled="disabled" value="disabledEntry">Auswahl Projekte</option>
                ${options}
              </select>
              <br /><div class='proje-allout-entry'>T&auml;tigkeit:</div>
              <input name="proje-taetigkeit[]" type="text" class="form-control proje-taetigkeit" maxLength="498" placeholder="" value="${taetigkeit}"  />
              <i title="Bearbeiten" data-value="${i}" class="bi bi-pencil-square infobar-icon padLeft10 pointer proje-edit"></i>
            </div>`;
  }

  return <div>
    <div className="modal fade" id="delProjeDialog" tabIndex="-1" role="dialog" aria-labelledby="delProjeDialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="delProjeDialog">Best&auml;tigung erforderlich</h5>
            <button type="button" className="btn-close closeDelDialog" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            Wollen Sie den Eintrag wirklich l&ouml;schen?
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary closeDelDialog" data-bs-dismiss="modal">Nein</button>
            <button id="execDelDialog" type="button" className="btn btn-primary">Ja</button>
          </div>
        </div>
      </div>
    </div>
    
    <div className="modal fade" id="editTaetProjeDialog" tabIndex="-1" aria-labelledby="editTaetProjeDialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="editTaetProjeDialog">T&auml;tigkeit bearbeiten</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
              <div className="mb-3">
              <textarea className="form-control" id="proje-taet-text-big" maxLength="498"></textarea>
              <div className="proje-taet-edit-info">max. 500 Zeichen</div>
              </div>
          </div>
          <div className="modal-footer">
            <button id="execEditDialog" type="button" className="btn btn-primary">Text &uuml;bernehmen</button>
          </div>
        </div>
      </div>
    </div>

    <div className="content">
      <div id="headline" className="headline">Projekte <i id="proje-save" title="Alles Speichern" className="bi bi-save infobar-icon padLeft10 pointer" onClick={e => handleClick(e.target.id, Helper, Storage, user)}></i></div>
      <div id="proje-allout"></div>
    </div>
  </div>;
}
