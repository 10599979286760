import React, { useState, useEffect } from "react";
import Requests from '../components/functions/requests';
import Storage from '../components/functions/storage';
import Helper from '../components/functions/helper';

export default function Home({ user }) {
  const [aktivProjektName, setAktivProjektName] = useState(null);

  let projekte = Storage.getStorageValue("dtm-Projekte");
  if (projekte) {
    projekte = JSON.parse(projekte);
  } else {
    projekte = [];
    projekte[0] = { id_projekt: '0', name: '' };
  }

  let workData = "";

  useEffect(() => {

    let elem = document.getElementById("infobarIconOnline");
    if (window.navigator.onLine) {
      elem.style.color = '#B1B5B9';
      elem.title = "Online";
    } else {
      elem.style.color = 'darkred';
      elem.title = "Offline";
    }

    let data = "";
    if (user) {
      const chkData = async (strWorkData) => {
        const reqData = await Requests.asyncDTMPostRequest("dtm-data", strWorkData, user);
        //const strReqData = JSON.stringify(reqData.data);
        data = reqData.data;
        if (strWorkData !== data) {
          Storage.setStorageValue("dtm-WorkData", data);
        }

        if (data?.length > 0) {
          const date = new Date();
          const now = Helper.getDate(date);
          const parsedWorkData = JSON.parse(data);
          disableButton(parsedWorkData, user.online);
          const az = parsedWorkData.az;
          if (az.hasOwnProperty("azstart")) {
            const azstart = az["azstart"];
            let azstartout = "";
            let azendeout = "<span class='text'>---</span>";
            let azstatus = "<span class='text'>in Arbeit</span>";
            if (azstart.length > 0 && azstart.split(' ')[0] === now) {
              const azende = az["azende"];
              if (azstart.length > 0) {
                azstartout = azstart.slice(-5);
                document.getElementById("az-start").disabled = true;
                if (azende?.length > 0) {
                  azendeout = azende.slice(-5);
                  azstatus = "<span class='text'>beendet</span>";
                }
              }
              const azout = getOutTempl(azstartout, azendeout, azstatus);
              let out = `<div class="container text-center">${azout}</div>`;
              document.getElementById("az-info").innerHTML = out;
      
              out = "";
              const projekte = parsedWorkData.projekte;
              if (projekte) {
                let projeout = "";
                for (let i = projekte.length - 1; i >= 0; i--) {
                  let start = (projekte[i]["projstart"] + "").slice(-5);
                  let ende = (projekte[i]["projende"] + "") === "" ? "<span class='text'>---</span>" : (projekte[i]["projende"] + "").slice(-5);
                  let projekt = (projekte[i]["projname"] + "");
                  projeout += getOutTempl(start, ende, projekt);
                }
                out = `<div class="container text-center">${projeout}</div>`;
                document.getElementById("proje-info").innerHTML = out;
              }

              out = "";
              const pausen = parsedWorkData.pausen;
              if (pausen) {
                let pauseout = "";
                for (let i = pausen.length - 1; i >= 0; i--) {
                  let start = (pausen[i]["pausestart"] + "").slice(-5);
                  let ende = (pausen[i]["pauseende"] + "") === "" ? "<span class='text'>---</span>" : (pausen[i]["pauseende"] + "").slice(-5);
                  let pausetext = (pausen[i]["pausetext"] + "");
                  pauseout += getOutTempl(start, ende, pausetext);
                }
                out = `<div class="container text-center">${pauseout}</div>`;
                document.getElementById("pause-info").innerHTML = out;
              }
            } else {
              Storage.removeStorageValue("dtm-WorkData");
            }
          }
        } else {
          const date = new Date();
          if (user.tdate !== Helper.getDate(date)) {
            Storage.deleteStorageValues();
            window.location.assign("/");
          }
          let out = "";
          if (reqData.token && reqData.token === "incorrect") {
            out = "Token incorrect!";
          } else if (reqData.error) {
            out = reqData.error;
          }
          document.getElementById("error-info").innerHTML = out;
        }
      };
      let strWorkData = Storage.getStorageValue("dtm-WorkData");
      chkData(strWorkData);
    } else {
      Storage.deleteStorageValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getOutTempl = (start, ende, text) => {
    return `<div class="buttonpane row align-items-start">
              <div class="div20">
                  ${start}
              </div>
              <div class="div20">
                  ${ende}
              </div>
              <div class="div60">
                  ${text}
              </div>
            </div>`;
  }

  const setActiveProject = (projekt) => {
    if (projekt !== "disabledEntry") {
      for (let i in projekte) {
        if (projekte[i].name === projekt) {
          setAktivProjektName(projekt);
          break;
        }
      }
    }
  }

  const disableButton = (parsedWorkData, online) => {
    if (parseInt(online) > 0) {
      const az = parsedWorkData.az;
      const azopen = az.hasOwnProperty("azstart") && az["azstart"].length > 0;
      const azclose = az.hasOwnProperty("azende") && az["azende"].length > 0;
      if (azclose) {
        let arr = [];
        arr["az-start"] = 0;
        arr["az-ende"] = 0;
        arr["proje-start"] = 0;
        arr["proje-ende"] = 0;
        arr["pause-start"] = 0;
        arr["pause-ende"] = 0;
        makeButtonOnOff(arr);
        document.getElementById("projekte-datalist").disabled = true;
      } else if (azopen) {
        let arr = [];
        arr["az-start"] = 0;
        arr["az-ende"] = 1;
        arr["proje-start"] = 1;
        arr["proje-ende"] = 0;
        arr["pause-start"] = 1;
        arr["pause-ende"] = 0;
        makeButtonOnOff(arr);
        document.getElementById("projekte-datalist").disabled = false;

        const projekte = parsedWorkData.projekte;
        let projopen = false;
        for (let i = 0; i < projekte.length; i++) {
          if (projekte[i]["projende"].length < 1) {
            projopen = true;
            break;
          }
        }

        const pausen = parsedWorkData.pausen;
        let pauseopen = false;
        for (let i = 0; i < pausen.length; i++) {
          if (pausen[i]["pauseende"].length < 1) {
            pauseopen = true;
            break;
          }
        }

        if (pauseopen) {
          let arr = [];
          arr["proje-start"] = 0;
          arr["proje-ende"] = 0;
          arr["pause-start"] = 0;
          arr["pause-ende"] = 1;
          makeButtonOnOff(arr);
          document.getElementById("projekte-datalist").disabled = true;
        } else if (projopen) {
          let arr = [];
          arr["proje-start"] = 0;
          arr["proje-ende"] = 1;
          arr["pause-start"] = 1;
          arr["pause-ende"] = 0;
          makeButtonOnOff(arr);
          document.getElementById("projekte-datalist").disabled = false;
        }
      } else {
        let arr = [];
        arr["az-start"] = 1;
        arr["az-ende"] = 0;
        arr["proje-start"] = 0;
        arr["proje-ende"] = 0;
        arr["pause-start"] = 0;
        arr["pause-ende"] = 0;
        makeButtonOnOff(arr);
        document.getElementById("projekte-datalist").disabled = true;
      }
    } else {
      const info = "Der Zugriff auf die Zeiterfassung ausserhalb <br />des Firmennetzwerkes ist bei Ihnen deaktiviert.";
      document.getElementById("online-info").innerHTML = info;
      let arr = [];
      arr["az-start"] = 0;
      arr["az-ende"] = 0;
      arr["proje-start"] = 0;
      arr["proje-ende"] = 0;
      arr["pause-start"] = 0;
      arr["pause-ende"] = 0;
      makeButtonOnOff(arr);
      document.getElementById("projekte-datalist").disabled = true;
    }
  }

  const makeButtonOnOff = (arr) => { 
    for (let key in arr) {
      let elem = document.getElementById(key);
      if (arr[key] > 0) {
        elem.className = elem.className.replace(" btn-outline-light", "");
        elem.className = elem.className.replace(" btn-outline-secondary", "");
        elem.className += " btn-outline-light";
        elem.disabled = false;
      } else {
        elem.className = elem.className.replace(" btn-outline-light", "");
        elem.className = elem.className.replace(" btn-outline-secondary", "");
        elem.className += " btn-outline-secondary";
        elem.disabled = true;
      }
    }
  }

  const handleClick = async (id, helper, storage, user) => {
    const date = new Date();
    if (user.tdate !== helper.getDate(date)) {
      storage.deleteStorageValues();
      window.location.assign("/");
    }

    switch (id) {
      case "az-start": {
        if (workData.length < 1) {
          let start = helper.getDateTime(date);
          workData = {
            az: {
              azid: 0,
              azstart: start,
              azende: '',
            },
            projekte: [],
            pausen: [],
          };
          const strWorkData = JSON.stringify(workData);
          const reqData = await Requests.asyncDTMPostRequest(id, strWorkData, user);
          if (reqData.azbeg) {
            if (strWorkData !== reqData.data) {
              window.location.assign("/");
            }
            storage.setStorageValue("dtm-WorkData", reqData.data);
            start = (workData.az["azstart"] + "").slice(-5);
            const ende = "<span class='text'>---</span>";
            const status = "<span class='text'>in Arbeit</span>";
            const azout = getOutTempl(start, ende, status);
            let out = `<div class="container text-center">${azout}</div>`;
            document.getElementById("az-info").innerHTML = out;
            disableButton(workData, user.online);
          } else {
            document.getElementById("az-info").innerHTML = "<span class='error'>Es ist ein Fehler aufgetreten.</span>";
          }
        }
        break;
      }
      case "az-ende": {
        let start = '';
        const date = new Date();
        workData = storage.getStorageValue("dtm-WorkData");
        if (workData.length > 0) {
          const parsedWorkData = JSON.parse(workData);
          start = (parsedWorkData.az["azstart"] + "").slice(-5);
          const ende = helper.getDateTime(date);
          parsedWorkData.az["azende"] = ende;
          const strWorkData = JSON.stringify(parsedWorkData);
          const reqData = await Requests.asyncDTMPostRequest(id, strWorkData, user);
          if (reqData.azend) {
            if (strWorkData !== reqData.data) {
              window.location.assign("/");
            }
            storage.setStorageValue("dtm-WorkData", reqData.data);
            let status = "<span class='text'>beendet</span>";
            const azout = getOutTempl(start, ende, status);
            let out = `<div class="container text-center">${azout}</div>`;
            document.getElementById("az-info").innerHTML = out;
            disableButton(parsedWorkData, user.online);
          } else {
            document.getElementById("az-info").innerHTML = "<span class='error'>Es ist ein Fehler aufgetreten.</span>";
          }
        }
        break;
      }
      case "proje-start": {
        const date = new Date();
        workData = storage.getStorageValue("dtm-WorkData");
        if (workData.length > 0 && aktivProjektName) {
          const parsedWorkData = JSON.parse(workData);
          const projekte = parsedWorkData.projekte;
          let ptext = document.getElementById("taetigkeit-text").value.trim();
          ptext = ptext.length > 0 ? ptext.replace("'", "") : "---";
          const projekt = { projstart: helper.getDateTime(date), projende: '', projname: aktivProjektName, projtaet: ptext }
          projekte.push(projekt);
          const strWorkData = JSON.stringify(parsedWorkData);
          const reqData = await Requests.asyncDTMPostRequest(id, strWorkData, user);
          if (reqData.projebeg) {
            if (strWorkData !== reqData.data) {
              window.location.assign("/");
            }
            storage.setStorageValue("dtm-WorkData", reqData.data);
            let projeout = "";
            for (let i = projekte.length - 1; i >= 0; i--) {
              let start = (projekte[i]["projstart"] + "").slice(-5);
              let ende = (projekte[i]["projende"] + "") === "" ? "<span class='text'>---</span>" : (projekte[i]["projende"] + "").slice(-5);
              let projekt = projekte[i]["projname"];
              projeout += getOutTempl(start, ende, projekt);
            }
            let out = `<div class="container text-center">${projeout}</div>`;
            document.getElementById("proje-info").innerHTML = out;
            disableButton(parsedWorkData, user.online);
          } else {
            document.getElementById("proje-info").innerHTML = "<span class='error'>Es ist ein Fehler aufgetreten.</span>";
          }
        } else {
          document.getElementById('projekte-datalist').focus();
          document.getElementById('projekte-datalist').value = "";
        }
        break;
      }
      case "proje-ende": {
        const date = new Date();
        workData = storage.getStorageValue("dtm-WorkData");
        if (workData.length > 0) {
          const parsedWorkData = JSON.parse(workData);
          const projekte = parsedWorkData.projekte;
          let i = projekte.length - 1;
          if (projekte[i]['projende'].length < 1) {
            const ptext = document.getElementById("taetigkeit-text").value.trim();
            if (ptext.length > 0) {
              projekte[i]['projtaet'] = ptext.replace("'", "");
            }
            projekte[i]['projende'] = helper.getDateTime(date);
          }
          const strWorkData = JSON.stringify(parsedWorkData);
          const reqData = await Requests.asyncDTMPostRequest(id, strWorkData, user);
          if (reqData.projeend) {
            if (strWorkData !== reqData.data) {
              window.location.assign("/");
            }
            storage.setStorageValue("dtm-WorkData", reqData.data);
            let projeout = "";
            for (let i = projekte.length - 1; i >= 0; i--) {
              let start = (projekte[i]["projstart"] + "").slice(-5);
              let ende = (projekte[i]["projende"] + "").slice(-5);
              let projekt = projekte[i]["projname"];
              projeout += getOutTempl(start, ende, projekt);
            }
            let out = `<div class="container text-center">${projeout}</div>`;
            document.getElementById("proje-info").innerHTML = out;
            disableButton(parsedWorkData, user.online);
          } else {
            document.getElementById("proje-info").innerHTML = "<span class='error'>Es ist ein Fehler aufgetreten.</span>";
          }
        }
        break;
      }
      case "pause-start": {
        const date = new Date();
        workData = storage.getStorageValue("dtm-WorkData");
        if (workData.length > 0) {
          const parsedWorkData = JSON.parse(workData);
          let pausen = parsedWorkData.pausen;
          let ptext = document.getElementById("pause-text").value.trim();
          if (ptext.length < 1) {
            ptext = "Pause";
          }
          let pause = { pausestart: helper.getDateTime(date), pauseende: '', pausetext: ptext.replace("'", "") }
          pausen.push(pause);
          const strWorkData = JSON.stringify(parsedWorkData);
          const reqData = await Requests.asyncDTMPostRequest(id, strWorkData, user);
          if (reqData.pausebeg) {
            if (strWorkData !== reqData.data) {
              window.location.assign("/");
            }
            storage.setStorageValue("dtm-WorkData", reqData.data);
            let pauseout = "";
            for (let i = pausen.length - 1; i >= 0; i--) {
              let start = (pausen[i]["pausestart"] + "").slice(-5);
              let ende = (pausen[i]["pauseende"] + "") === "" ? "<span class='text'>---</span>" : (pausen[i]["pauseende"] + "").slice(-5);
              ptext = pausen[i]["pausetext"];
              pauseout += getOutTempl(start, ende, ptext);
            }
            let out = `<div class="container text-center">${pauseout}</div>`;
            document.getElementById("pause-info").innerHTML = out;
            disableButton(parsedWorkData, user.online);
          } else {
            document.getElementById("pause-info").innerHTML = "<span class='error'>Es ist ein Fehler aufgetreten.</span>";
          }
        }
        break;
      }
      case "pause-ende": {
        const date = new Date();
        workData = storage.getStorageValue("dtm-WorkData");
        if (workData.length > 0) {
          const parsedWorkData = JSON.parse(workData);
          let pausen = parsedWorkData.pausen;
          let i = pausen.length - 1;
          let ptext = document.getElementById("pause-text").value.trim();
          if (ptext.length < 1) {
            ptext = "Pause";
          }
          if (pausen[i]['pauseende'].length < 1) {
            pausen[i]['pauseende'] = helper.getDateTime(date);
            pausen[i]['pausetext'] = ptext.replace("'", "");
          }
          const strWorkData = JSON.stringify(parsedWorkData);
          const reqData = await Requests.asyncDTMPostRequest(id, strWorkData, user);
          if (reqData.pauseend) {
            if (strWorkData !== reqData.data) {
              window.location.assign("/");
            }
            storage.setStorageValue("dtm-WorkData", reqData.data);
            let pauseout = "";
            for (let i = pausen.length - 1; i >= 0; i--) {
              let start = (pausen[i]["pausestart"] + "").slice(-5);
              let ende = (pausen[i]["pauseende"] + "").slice(-5);
              ptext = pausen[i]['pausetext'];
              pauseout += getOutTempl(start, ende, ptext);
            }
            let out = `<div class="container text-center">${pauseout}</div>`;
            document.getElementById("pause-info").innerHTML = out;
            disableButton(parsedWorkData, user.online);
          } else {
            document.getElementById("pause-info").innerHTML = "<span class='error'>Es ist ein Fehler aufgetreten.</span>";
          }
        }
        break;
      }
      default:
        break;
    }
  }

  return <form>
    <div className="content">
      <div id="online-info"></div>
      <div id="error-info"></div>
      <div className="headline">Arbeitszeit</div>
      <div className="buttonpane-out">
        <div className="buttonpane">
          <button id="az-start" type="button" className="btn btn-outline-secondary" onClick={e => handleClick(e.target.id, Helper, Storage, user)}>Start</button>
          <button id="az-ende" type="button" className="btn btn-outline-secondary" onClick={e => handleClick(e.target.id, Helper, Storage, user)}>Ende</button>
        </div>
        <div id="az-info"></div>
      </div>
      <div className="headline">Projekt</div>
      <div className="buttonpane-out">
        <div className="buttonpane">
          <select className="form-control" id="projekte-datalist" placeholder="Projektauswahl" defaultValue="disabledEntry" onChange={e => setActiveProject(e.target.value)}>
            <option key="0" disabled="disabled" value="disabledEntry">Auswahl Projekte</option>
            {projekte.map((item) => (
              <option key={item.id_projekt} value={item.name}>{item.name}</option>
            ))}
          </select>
        </div>
        <div className="buttonpane">
          <input id="taetigkeit-text" type="text" className="form-control" maxLength="498" placeholder="optional T&auml;tigkeit angeben" />
        </div>
        <div className="buttonpane">
          <button id="proje-start" type="button" className="btn btn-outline-secondary" onClick={e => handleClick(e.target.id, Helper, Storage, user)}>Start</button>
          <button id="proje-ende" type="button" className="btn btn-outline-secondary" onClick={e => handleClick(e.target.id, Helper, Storage, user)}>Ende</button>
        </div>
        <div id="proje-info"></div>
      </div>
      <div className="headline">Unterbrechung</div>
      <div className="buttonpane-out">
        <div className="buttonpane">
          <input id="pause-text" type="text" className="form-control" maxLength="248" placeholder="Grund f&uuml;r die Unterbrechung (Pause)" />
        </div>
        <div className="buttonpane">
          <button id="pause-start" type="button" className="btn btn-outline-secondary" onClick={e => handleClick(e.target.id, Helper, Storage, user)}>Start</button>
          <button id="pause-ende" type="button" className="btn btn-outline-secondary" onClick={e => handleClick(e.target.id, Helper, Storage, user)}>Ende</button>
        </div>
        <div id="pause-info"></div>
      </div>
    </div>
  </form>;
}
