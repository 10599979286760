
export default class Storage {

    static setStorageValue = (name, value) => {
        localStorage.setItem(name, value);
    }

    static getStorageValue = (name) => {
        const item = localStorage.getItem(name);
        if (item && item.indexOf('undefined') < 0) {
            return item;
        } else {
            return "";
        }
    }

    static removeStorageValue = (name) => {
        if (localStorage.getItem(name) !== null) {
            localStorage.removeItem(name);
            return true;
        } else {
            return false;
        }
    }

    static deleteStorageValues = () => {
        localStorage.clear();
    }
}
