import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-icons/font/bootstrap-icons.min.css";
import "./App.css";

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "../sites/login";
import Home from "../sites/home";
import Info from "../sites/info";
import Projekte from "../sites/projekte";
import Anwesende from "../sites/anwesende";
import Preferences from "../sites/preferences";

import Logo from "../components/logo";
import Navbar from "../components/navbar";
import Infobar from "../components/infobar";
import useToken from "../components/functions/useToken";
import Storage from '../components/functions/storage';
import Helper from '../components/functions/helper';

function App() {
  const { userToken, setToken } = useToken();
  
  let user = null;
  if (userToken && userToken.length > 0) {
    user = JSON.parse(userToken);
  }

  if (user) {
    const date = new Date();
    if (user.tdate !== Helper.getDate(date)) {
      Storage.deleteStorageValues();
    }
  }

  if (!user?.token) {
    return (
      <div data-bs-theme="dark">
        <Logo />
        <Login setToken={setToken} logOut='logIn' />
        <Infobar />
      </div>
    );
  }

  return (
    <div data-bs-theme="dark">
      <Router>
        <Logo />
        <Navbar user={user} />
        <Routes>
          <Route exact path="/" element={<Home user={user} />} />
          <Route exact path="/info" element={<Info />} />
          <Route exact path="/anwesende" element={<Anwesende user={user} />} />
          <Route exact path="/projekte" element={<Projekte user={user} />} />
          <Route exact path="/preferences" element={<Preferences />} />
          <Route exact path="/login" element={<Login setToken={setToken} logOut='logOut' />} />
        </Routes>
      </Router>
      <Infobar user={user} />
    </div>
  );
}

export default App;
