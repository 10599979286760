import React, { useEffect } from "react";
import Requests from '../components/functions/requests';

export default function Anwesende({ user }) {

  useEffect(() => {
    const getData = async () => {
      let formData = new FormData();
      formData.append("uid", user.id_user);
      formData.append("uname", user.login);
      formData.append("token", user.token);
      formData.append("action", "get-worker");
      const data = await Requests.asyncPostRequest(formData);
      const worker = data.worker;
      if (data.error) {
        document.getElementById("error-info").innerHTML = "<span class='error'>Es ist ein Fehler aufgetreten.</span>";
      } else {
        let out = `<div class="container">
                    <div class="row align-items-start">
                      <div style='width:40%;font-size:14px;color:var(--bs-info-text-emphasis);'>
                        Name
                      </div>
                      <div style='width:15%;font-size:14px;color:var(--bs-info-text-emphasis);'>
                        Arbeit
                      </div>
                      <div style='width:15%;font-size:14px;color:var(--bs-info-text-emphasis);'>
                        Pause
                      </div>
                      <div style='width:30%;font-size:14px;color:var(--bs-info-text-emphasis);'>
                        &nbsp;
                      </div>
                    </div>
                  </div>`;
        out += `<div>`;
        for (const work of worker) {
          if (!work.azend && (!work.abw || work.abw === "Homeoffice" || work.abw === "Dienstreise")) {
            out += getOutTemplateOn(work);
          }
        }
        out += `</div>`;
        document.getElementById("anwesend").innerHTML = out;
        out = `<div class="container">
                  <div class="row align-items-start">
                    <div style='width:40%;font-size:14px;color:var(--bs-info-text-emphasis);'>
                      Name
                    </div>
                    <div style='width:60%;font-size:14px;color:var(--bs-info-text-emphasis);'>
                      Abwesend
                    </div>
                  </div>
                </div>`;
        out += `<div>`;
        for (const work of worker) {
          if ((work.azbeg && work.azend) || (work.abw && work.abw !== "Homeoffice" && work.abw !== "Dienstreise")) {
            out += getOutTemplateOff(work);
          }
        }
        out += `</div>`;
        document.getElementById("abwesend").innerHTML = out;
      }
    }
    getData();
  });

  const getOutTemplateOn = (work) => {
    return `<div class="container">
              <div class="row align-items-start">
                <div style='color:silver;width:40%;font-size:14px;'>
                  ${work.vorname}&nbsp;${work.name}
                </div>
                <div style='color:silver;width:15%;font-size:14px;'>
                  ${work.azbeg ? work.azbeg.slice(-5) : "-"}
                </div>
                <div style='color:silver;width:15%;font-size:14px;'>
                  ${work.pbeg ? work.pbeg.slice(-5) : ""}
                </div>
                <div style='color:silver;width:30%;font-size:14px;'>
                  ${work.pause ? work.pause : ""}
                </div>
              </div>
            </div>`;
  }

  const getOutTemplateOff = (work) => {
    return `<div class="container">
              <div class="row align-items-start">
                <div style='color:silver;width:40%;font-size:14px;'>
                  ${work.vorname}&nbsp;${work.name}
                </div>
                <div style='color:silver;width:60%;font-size:14px;'>
                  ${work.azbeg && work.azend ? "Arbeitszeit beendet" : work.abw ? work.abw : ""}                  
                </div>
              </div>
            </div>`;
  }

  return <div className="content padBottom20 padlr20">
           <div id="error-info"></div>
           <div className="headline">Mitarbeiter</div>
           <div id="anwesend" className="padBottom30"></div>
           <div id="abwesend"></div>
         </div>;
}
