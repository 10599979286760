import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register("./sw.js", { scope: "/" });
// }

navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for(let registration of registrations) {
        registration.unregister();
    } 
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

