import { useState } from 'react';
import Storage from "./storage.jsx";

export default function useToken() {
  const getToken = () => {
    const storageValue = Storage.getStorageValue('dtm-userToken');
    if (storageValue !== null) {
      return storageValue;
    }
    return "";
  };

  const [userToken, setToken] = useState(getToken());

  const saveToken = userData => {
    Storage.setStorageValue('dtm-userToken', userData);
    setToken(userData);
  };

  return {
    setToken: saveToken,
    userToken
  }
}
