import config from "../../manifest.json";

export default class Requests {

    //static requestURL = 'http://127.0.0.1/zeit/api.php';
    static requestURL = config.api_url;

    static asyncPostRequest = async (formData) => {
        return fetch(this.requestURL, {
            method: 'POST',
            body: formData
        }).then(data => data.json()
        ).catch(error => {
            console.log(error);
        });
        //).catch(data => "ERRORconnect");
    }
    
    static asyncDTMPostRequest = async (action, workData, user) => { 
        let formData = new FormData();
        formData.append("uid", user.id_user);
        formData.append("uname", user.login);
        formData.append("token", user.token);
        formData.append("data", workData);
        formData.append("action", action);        
        return fetch(this.requestURL, {
            method: 'POST',
            body: formData
        }).then(data => data.json()
        ).catch(error => {
            console.log(error);
        });
        //).catch(data => "ERRORconnect");
    }
}
