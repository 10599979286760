
export default class Helper {

    static getDateTime = (date) => {
        let day = date.getDate();
        if (day < 10) { day = '0' + day; }
        let month = date.getMonth() + 1;
        if (month < 10) { month = '0' + month; }
        let hour = date.getHours()
        if (hour < 10) { hour = '0' + hour; }
        let minute = date.getMinutes()
        if (minute < 10) { minute = '0' + minute; }
        return day + "." + month + "." + date.getFullYear() + " " + hour + ":" + minute;
    }

    static getDate = (date) => {
        let day = date.getDate();
        if (day < 10) { day = '0' + day; }
        let month = date.getMonth() + 1;
        if (month < 10) { month = '0' + month; }
        return day + "." + month + "." + date.getFullYear();
    }

    static getTime = (date) => {
        let hour = date.getHours()
        if (hour < 10) { hour = '0' + hour; }
        let minute = date.getMinutes()
        if (minute < 10) { minute = '0' + minute; }
        return hour + ":" + minute;
    }
}
