import React from "react";

export default function Info() {
  return <div className="content">
          <div className="headline">Information</div>
          <div className="padLeft20">
            <a target="_blank" rel="noreferrer" className="bi bi-link-45deg nav-link" href="https://www.deiko-systems.de">&nbsp; Deiko-Systems</a>
            <a target="_blank" rel="noreferrer" className="bi bi-link-45deg nav-link" href="https://www.deiko-systems.de/deiko_impressum.php">&nbsp; Impressum</a>
            <a target="_blank" rel="noreferrer" className="bi bi-link-45deg nav-link" href="https://www.deiko-systems.de/deiko_datenschutzerklaerung.php">&nbsp; Datenschutzerkl&auml;rung</a>
          </div>
          <div className="headline">Partner</div>
          <div className="padLeft20">
            <a target="_blank" rel="noreferrer" className="bi bi-link-45deg nav-link" href="https://www.nestler-system.de">&nbsp; Nestler System - The Future of Project Management</a>
          </div>
        </div>
}
