import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Requests from '../components/functions/requests';
import Storage from '../components/functions/storage';

export default function Login({ setToken, logOut }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [loginerror, setLoginError] = useState();

    if (logOut === "logOut") { 
        Storage.deleteStorageValues();
        window.location.assign("/");
    }

    const handleSubmit = async e => {
        e.preventDefault();
        let formData = new FormData();
        formData.append("username", username.toLowerCase());
        formData.append("password", password);
        formData.append("get", "getToken");
        formData.append("get2", "getProject");
        formData.append("url", window.appApiUrl);
        let data = await Requests.asyncPostRequest(formData);
        let ret = data;
        if (ret === "ERRORconnect" || ret.data === "ERRORconnect") {
            setLoginError("Error, Connect to Server failed");
        } else if (ret === "ERROR" || ret.data === "ERROR") {
            setLoginError("Login failed");
        } else { 
            ret = data.data;
            const jdata = ret[0];
            setToken(JSON.stringify(jdata));
            Storage.setStorageValue("dtm-Projekte", JSON.stringify(data.data2));
        }
    }
    
    return  <div className="pad20">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="login" className="form-label">Login</label>
                        <input type="text" className="form-control" id="login" onChange={e => setUserName(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input type="password" className="form-control" id="password" onChange={e => setPassword(e.target.value)} />
                    </div>
                    <button type="submit" id="submit-login" className="btn btn-primary">Anmelden</button>
                    <div className="loginerror">{loginerror}</div>
                </form>
            </div>;
}
 
Login.propTypes = {
  setToken: PropTypes.func.isRequired
}

